import React from 'react'
import {
  DosingReportDocument, DosingReportDownloadDocument,
  DosingReportDownloadQuery,
  DosingReportQuery,
  DosingReportQueryVariables,
  Reports,
  SortDirection
} from '$typings/graphql-codegen';
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {SortObject, useGenericReport} from "$pages/reports-react/generic-report";
import {ColumnDefinition} from "$pages/reports-react/column-definition";
import {ColumnWidth} from "$pages/reports-react/ColumnWidth";

type ReportResult = DosingReportQuery
type ReportVariables = DosingReportQueryVariables
type ReportData = DosingReportQuery["reports"]["dosing"]["data"]["data"][0]
type Product = DosingReportQuery["reports"]["dosing"]["data"]["data"][0]["productData"]
type ReportDownloadResult = DosingReportDownloadQuery

enum ColumnKey {
  Alias = 'alias',
  ChannelAlias = 'channelAlias',
  Buid = 'buid',
  AccessTag = 'accessTag',
  ProductString = 'productString',
  DoseAvgString = 'doseAvgString',
  DoseAvg = 'doseAvgString',
  PreviousDoseAvgString = 'previousDoseAvgString',
  PreviousDoseAvg = 'previousDoseAvg',
  DoseSumString = 'doseSumString',
  DoseSum = 'doseSum',
  PreviousDoseSumString = 'previousDoseSumString',
  PreviousDoseSum = 'previousDoseSum',
  DeviationString = 'deviationString',
  Deviation = 'deviation',
}

interface PrettifiedReportData extends ReportData {
  [ColumnKey.ProductString]: string
  [ColumnKey.DoseAvgString]: string
  [ColumnKey.PreviousDoseAvgString]: string
  [ColumnKey.DoseSumString]: string
  [ColumnKey.PreviousDoseSumString]: string
  [ColumnKey.DeviationString]: string
}

const DosingReport = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Title: string = t('UI_Reports_Dosing')
  const Filename: string = 'dosing-report'

  // Used initially when table loads
  const DefaultSortSettings: SortObject = {
    sortColumnKey: ColumnKey.Alias,
    sortDirection: SortDirection.Asc
  }
  
  // Define columns
  const ColumnDefinitions: ColumnDefinition<PrettifiedReportData>[] = [
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_SiteAlias'),
      (row: PrettifiedReportData) => row[ColumnKey.Alias] ?? '',
      ColumnKey.Alias,
      ColumnWidth.Common.SiteName,
      (row: PrettifiedReportData) => `/sitedetails/${row.siteId}`
    ),
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_ChannelAlias'),
      (row: PrettifiedReportData) => row[ColumnKey.ChannelAlias] ?? '',
      ColumnKey.ChannelAlias,
      ColumnWidth.Common.ChannelName
    ),
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_BUID'),
      (row: PrettifiedReportData) => row[ColumnKey.Buid] ?? '',
      ColumnKey.Buid,
      ColumnWidth.Common.Buid
    ),
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_AccessTag'),
      (row: PrettifiedReportData) => row[ColumnKey.AccessTag] ?? '',
      ColumnKey.AccessTag,
      ColumnWidth.Common.AccessTag
    ),
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_Product'),
      (row: PrettifiedReportData) => row[ColumnKey.ProductString] ?? '',
      ColumnKey.ProductString,
      ColumnWidth.Common.ProductName
    ),
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_DoseAvg'),
      (row: PrettifiedReportData) => row[ColumnKey.DoseAvgString] ?? '',
      ColumnKey.DoseAvg,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_PreviousDoseAvg'),
      (row: PrettifiedReportData) => row[ColumnKey.PreviousDoseAvgString] ?? '',
      ColumnKey.PreviousDoseAvg,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_DoseSum'),
      (row: PrettifiedReportData) => row[ColumnKey.DoseSumString] ?? '',
      ColumnKey.DoseSum,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_PreviousDoseSum'),
      (row: PrettifiedReportData) => row[ColumnKey.PreviousDoseSumString] ?? '',
      ColumnKey.PreviousDoseSum,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_Dosing_Table_Header_Deviation'),
      (row: PrettifiedReportData) => row[ColumnKey.DeviationString] ?? '',
      ColumnKey.Deviation,
      ColumnWidth.Generic.Short
    ),
  ]
  
  function stringifyProduct(product: Product | null): string {
    if (product?.languageKey) {
      return t(product.languageKey)
    }
    return product?.name ?? ''
  }
  
  function postfix(stem?: number | null, suffix?: string | null): string {
    if (stem && suffix) {
      return `${stem} ${suffix}`
    }
    return String(stem ?? '')
  }
  
  function formatPercent(value?: number | null): string {
    if (value === undefined || value === null) return ''
    const fixed = value.toFixed(2)
    return `${fixed} %`
  }
  
  function prettifyData(data: ReportData): PrettifiedReportData {
    const pretty = {...data} as PrettifiedReportData
    pretty[ColumnKey.ProductString] = stringifyProduct(data.productData)
    pretty[ColumnKey.DoseAvgString] = postfix(data.doseAvg, data.doseAvgUnit)
    pretty[ColumnKey.PreviousDoseAvgString] = postfix(data.previousDoseAvg, data.doseAvgUnit)
    pretty[ColumnKey.DoseSumString] = postfix(data.doseSum, data.doseSumUnit)
    pretty[ColumnKey.PreviousDoseSumString] = postfix(data.previousDoseSum, data.doseSumUnit)
    pretty[ColumnKey.DeviationString] = formatPercent(data.deviation)
    return pretty
  }
  
  function dataSelector(result: ReportResult): PrettifiedReportData[] {
    const data: ReportData[] = (result?.reports?.dosing?.data?.data ?? []) as ReportData[]
    return data.map(prettifyData)
  }
  
  function dataCounter(result: ReportResult): number {
    return dataSelector(result).length
  }
  
  function downloadUrlSelector(result: ReportDownloadResult): string {
    return result?.reports?.dosing?.blobUrl ?? ''
  }
  
  const {GenericReport} = useGenericReport<
    ReportResult,
    ReportVariables,
    ReportData,
    ReportDownloadResult
  >()
  
  function createTitle(): JSX.Element {
    return <div>{ Title }</div>
  }
  
  return <GenericReport
    titleCallback={createTitle}
    reportEnum={Reports.Dosing}
    mainQueryDocument={DosingReportDocument}
    downloadQueryDocument={DosingReportDownloadDocument}
    columnDefinitions={ColumnDefinitions}
    dataSelector={dataSelector}
    dataCounter={dataCounter}
    defaultSortSettings={DefaultSortSettings}
    downloadUrlSelector={downloadUrlSelector}
    downloadFilename={Filename}
    allowRunningUnfiltered={false} />
}

export default DosingReport

import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";
import DosingReport from "$pages/reports-react/dosing-report/dosing-report";

class DosingReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(DosingReport);
  }
}

export default DosingReportReactWrapper
